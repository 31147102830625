interface Base64Img {
  img: File,
  base64: string,
}

export function img2Base64(img: File) {
  return new Promise<Base64Img>((resolve, reject) => {
    const picReader = new FileReader();

    picReader.readAsDataURL(img);
    picReader.addEventListener('load', () => {
      resolve({ img, base64: picReader.result as string ?? '' });
    });
  });
}
